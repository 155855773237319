import React, { useState, useEffect, useRef } from "react";
import "./Layout.css";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import {
  Breadcrumb,
  Input,
  Layout,
  Menu,
  theme,
  Carousel,
  Dropdown,
  Divider,
  Select,
} from "antd";
import { IoHomeOutline, IoMenuOutline } from "react-icons/io5";
import { FaSquareShareNodes } from "react-icons/fa6";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FacebookPage from "../facebook/FacebookPage";
import { useTranslation } from "react-i18next";
import PostCard from "../../post/PostCard";
import PostList from "../../post/PostList";
import Announcements from "../../../Announcements/Announcements";
import { FcPrevious } from "react-icons/fc";
import { FcNext } from "react-icons/fc";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { FaRegClock } from "react-icons/fa";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { SiLogitechg } from "react-icons/si";
import { GiTechnoHeart } from "react-icons/gi";
import { MdOutlineModeOfTravel } from "react-icons/md";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
const { Header, Content, Footer } = Layout;
const { Option } = Select;

const LayoutPage = () => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const [isIconVisable, setIsIconVisable] = useState(false);
  const sliderRef = useRef(null); // Reference to the slider
  const items = [
    {
      key: "1",
      label: t("home"),
      icon: <IoHomeOutline />,
    },
    {
      key: "2",
      label: t("share"),
      icon: <FaSquareShareNodes />,
    },
    {
      key: "3",
      label: t("technology"),
      icon: <SiLogitechg />,
    },
    {
      key: "4",
      label: t("health"),
      icon: <GiTechnoHeart />,
    },
    {
      key: "5",
      label: t("travel"),
      icon: <MdOutlineModeOfTravel />,
    },
  ];

  const onLinkPage = (route) => {
    navigate(route);
  };

  const onClickMenu = (e) => {
    if (e.key === "1") {
      onLinkPage("/home");
    } else if (e.key === "2") {
      onLinkPage("/share");
    } else if (e.key === "3") {
      onLinkPage("/technology");
    } else if (e.key === "4") {
      onLinkPage("/health");
    } else if (e.key === "5") {
      onLinkPage("/travel");
    }
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const handleResize = () => {
    if (window.innerWidth <= 560) {
      setIsMenuVisible(false);
      setIsIconVisable(true);
    } else {
      setIsMenuVisible(true);
      setIsIconVisable(false);
    }
  };

  useEffect(() => {
    handleResize(); // Initialize the state based on the initial window size
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {}, [isMenuVisible, isIconVisable]);
  const menu = (
    <Menu onClick={onClickMenu} items={items} className="custom-menu" />
  );
  const toggleMenu = () => {};
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // Hide the default arrows
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const onCLickTechnology = () => {
    onLinkPage("/technology");
  };
  const onClickHome = () => {
    onLinkPage("/home");
  };
  const onClickHealth = () => {
    onLinkPage("/health");
  };
  const onCLickTravel = () => {
    onLinkPage("/travel");
  };
  const onClickAbout = () => {
    onLinkPage("/about");
  };

  return (
    <Layout style={{ backgroundColor: "#EAFAF1" }}>
      <Header
        style={{
          background: "#34495E",
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="demo" />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: 30, height: 30 }}
            src="https://www.freepnglogos.com/uploads/logo-website-png/logo-website-website-icon-with-png-and-vector-format-for-unlimited-22.png"
          />
          <p className="demo-div-p">Daily News</p>
        </div>
        {isMenuVisible && (
          <Menu
            onClick={onClickMenu}
            mode="horizontal"
            defaultSelectedKeys={["1"]}
            items={items}
            className="custom-menu"
            style={{
              flex: 1,
              minWidth: 0,
              color: "white",
              backgroundColor: "#34495E",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        )}

        <Input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginLeft: 10, padding: 10, width: 200, borderRadius: 50 }}
        />
        <div className="custom-change-language">
          <img
            onClick={() => changeLanguage("kh")}
            src="https://vectorflags.s3.amazonaws.com/flags/kh-circle-01.png"
            alt="kh"
          />
          <img
            onClick={() => changeLanguage("en")}
            src="https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/800px-Flag_of_the_United_Kingdom.svg.png"
            alt="kh"
          />
        </div>

        {isIconVisable && (
          <div className="menu-icon" onClick={toggleMenu}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <IoMenuOutline />
            </Dropdown>
          </div>
        )}
      </Header>
      <div style={{ height: "0.5vh", backgroundColor: "#27AE60" }}></div>
      <div className="slider-container">
        <Slider ref={sliderRef} {...settings}>
          <div>
            <img src="https://images.pexels.com/photos/694745/pexels-photo-694745.jpeg" />
          </div>
          <div>
            <img src="https://images.pexels.com/photos/694745/pexels-photo-694745.jpeg" />
          </div>
        </Slider>

        <div className="slider-button prev-button" onClick={prevSlide}>
          <FcPrevious style={{ height: 30, width: 30 }} />
        </div>
        <div className="slider-button next-button" onClick={nextSlide}>
          <FcNext style={{ height: 30, width: 30 }} />
        </div>

        <div className="slider-button-container">
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1, fontSize: "4rem", fontWeight: "bold" }}>
              Daily News
            </div>
            <div style={{ flex: 1, fontSize: "1.2rem" }}>
              Your go-to source for the latest news and updates, delivered fresh
              every day. Stay informed with Daily News.
            </div>
          </div>
        </div>
      </div>

      <div className="layout-topcenter">
        <div className="main-content">
          <div onClick={onClickHome} style={{ cursor: "pointer" }}>
            <img
              src="https://www.energy.gov/sites/default/files/styles/full_article_width/public/2023-04/Homeowner_F.png?itok=4krhzaze"
              style={{ height: 100, width: 100, borderRadius: 50 }}
              alt=""
            />
            <p style={{ textAlign: "center" }}>{t("home")}</p>
          </div>
        </div>
        <div className="main-content">
          <div onClick={onCLickTechnology} style={{ cursor: "pointer" }}>
            <img
              src="https://i-tea.com.my/assets/template/com_template/images/slider/digital-solution.png?v=0.05"
              style={{ height: 100, width: 100, borderRadius: 50 }}
              alt=""
            />
            <p style={{ textAlign: "center" }}>{t("manager")}</p>
          </div>
        </div>
        <div className="main-content">
          <div onClick={onClickHealth} style={{ cursor: "pointer" }}>
            <img
              src="https://cdn.pixabay.com/photo/2023/07/04/19/43/man-8106958_1280.png"
              style={{ height: 100, width: 100, borderRadius: 50 }}
              alt=""
            />
            <p style={{ textAlign: "center" }}>{t("staff")}</p>
          </div>
        </div>
        <div className="main-content">
          <div onClick={onCLickTravel} style={{ cursor: "pointer" }}>
            <img
              src="https://img.freepik.com/free-vector/tiny-people-sitting-large-speech-bubbles-smartphone-online-message-flat-vector-illustration-social-media-digital-technology_74855-13259.jpg"
              style={{ height: 100, width: 100, borderRadius: 50 }}
              alt=""
            />
            <p style={{ textAlign: "center" }}>{t("news")}</p>
          </div>
        </div>
        <div className="main-content">
          <div onClick={onClickAbout} style={{ cursor: "pointer" }}>
            <img
              src="https://img.freepik.com/premium-vector/freelancer-working-from-home-beach-relaxed-pace-convenient-workplace-concept-colored-flat-vector-illustration-isolated_612079-2858.jpg?size=626&ext=jpg&ga=GA1.1.2082370165.1716681600&semt=ais_user"
              style={{ height: 100, width: 100, borderRadius: 50 }}
              alt=""
            />
            <p style={{ textAlign: "center" }}>{t("aboutme")}</p>
          </div>
        </div>
      </div>
      <div
        style={{
          marginLeft: 20,
          display: "flex",
        }}
      >
        <button
          onClick={() => {
            window.open(
              "https://web.facebook.com/profile.php?id=61556821611898"
            );
          }}
          style={{
            height: 40,
            paddingLeft: 10,
            paddingRight: 10,
            background: "#1E8449",
            border: "none",
            color: "white",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          <FaFacebookF style={{ marginRight: 5 }} />
          Facebook
        </button>
        <button
          onClick={() => {
            window.open(
              "https://web.facebook.com/profile.php?id=61556821611898"
            );
          }}
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            marginLeft: 10,
            background: "#2E4053",
            border: "none",
            color: "white",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          {" "}
          <FaInstagram style={{ marginRight: 5 }} />
          Instagram
        </button>
        <button
          onClick={() => {
            window.open("https://www.youtube.com/@raksmeyincomputing");
          }}
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            marginLeft: 10,
            background: "#7B241C",
            border: "none",
            color: "white",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          {" "}
          <FaYoutube style={{ marginRight: 5 }} />
          YouTube
        </button>
      </div>

      <div className="layout-center">
        <div className="layout-left">
          <Content style={{ marginTop: 5, padding: "0 20px 10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  height: "auto",
                  width: "100%",
                  padding: 10,
                  minHeight: 380,
                  marginTop: 10,
                  background: colorBgContainer,
                  borderRadius: borderRadiusLG,
                }}
              >
                <Outlet context={{ searchTerm }} />
              </div>
            </div>
          </Content>
        </div>

        <div
        // className={`layout-right ${
        //   location.pathname === "/home" ||
        //   location.pathname === "" ||
        //   location.pathname === "/"
        //     ? "hide-layout-right"
        //     : ""
        // }`}
        >
          <div
            style={{
              marginRight: 10,
              background: "white",
              borderTopLeftRadius: 7,
              borderTopRightRadius: 7,
              textAlign: "center",
            }}
          >
            <div
              style={{
                borderTopLeftRadius: 7,
                borderTopRightRadius: 7,
                background: "linear-gradient(90deg, #2980B9, #27AE60)",
              }}
            >
              <p
                style={{
                  paddingTop: 15,
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {t("followme")}
              </p>{" "}
            </div>{" "}
            <div style={{ padding: 10 }}>
              <FacebookPage
                pageUrl="https://web.facebook.com/chraksmeyy" // Replace with your Facebook page URL
                height="120px"
              />
            </div>
          </div>
          {/* <div
            style={{
              marginRight: 10,
              background: "white",
              borderTopLeftRadius: 7,
              borderTopRightRadius: 7,
              textAlign: "center",
            }}
          >
            <div
              style={{
                borderTopLeftRadius: 7,
                borderTopRightRadius: 7,
                background: "linear-gradient(90deg, #BDC3C7, #27AE60)",
              }}
            >
              <p
                style={{
                  paddingTop: 15,
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                ព្រឹត្តិការណ៍ថ្មីៗ
              </p>{" "}
              <div>
                <FacebookPage
                  pageUrl="https://web.facebook.com/chraksmeyy" // Replace with your Facebook page URL
                  width="340"
                  height="120"
                />
              </div>
            </div>
          </div> */}

          <div
            style={{
              marginRight: 10,
              background: "white",
              borderTopLeftRadius: 7,
              borderTopRightRadius: 7,
              textAlign: "center",
            }}
          >
            <div
              style={{
                borderTopLeftRadius: 7,
                borderTopRightRadius: 7,
                background: "linear-gradient(90deg, #FADBD8, #27AE60)",
              }}
            >
              <p
                style={{
                  paddingTop: 15,
                  color: "white",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {t("announcements")}
              </p>{" "}
            </div>
            <div>
              <Announcements />
            </div>
          </div>
        </div>
      </div>

      <Footer className="footer">
        <div className="footer-column">
          <div
            style={{
              width: 200,
              borderRadius: 40,
              paddingLeft: 5,
              paddingTop: 5,
              display: "flex",
              background: "#117A65",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <div>
              <img
                style={{ height: 40, width: 40, borderRadius: "50%" }}
                src="https://reportapi.devcomputing.pro/photo/hmis_staff/FB_IMG_1696517348119.jpg"
                alt="Raksmey Chann"
                className="footer-logo"
              />
            </div>
            <div style={{ paddingLeft: 10, lineHeight: 0.5 }}>
              <p style={{ fontWeight: "bold" }}>Raksmey Chann</p>
              <p style={{ fontSize: 10 }}>Phnom Penh, Cambodia</p>
            </div>
          </div>
          <p>
            <span>
              <MdOutlinePhoneInTalk />
            </span>{" "}
            0885 290 240 / 08921 33 39 <br />
            <span>
              <FaRegClock />
            </span>{" "}
            Office Hours: Mon-Sat: 7am-11:30am & 1:30pm-8:30pm, Sun: 7am-5pm{" "}
            <br />
            <span>
              <MdOutlineMarkEmailRead />
            </span>{" "}
            raksmey.itmoh@gmail.com <br />
            <span>🌐</span>{" "}
            <a href="https://www.devcomputing.pro" style={{ color: "white" }}>
              devcomputing.pro
            </a>
          </p>
          <div className="footer-socials">
            <a href="#">
              <img
                style={{ height: "30px", width: "30px" }}
                src="https://freepnglogo.com/images/all_img/1713419057Facebook_PNG.png"
                alt="Facebook"
              />
            </a>
            <a href="#">
              <img
                style={{ height: "30px", width: "30px" }}
                src="https://www.freepnglogos.com/uploads/logo-website-png/logo-website-website-icon-with-png-and-vector-format-for-unlimited-22.png"
                alt="Website"
              />
            </a>
            <a href="#">
              <img
                style={{ height: "30px", width: "30px" }}
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/2048px-Telegram_logo.svg.png"
                alt="telegram"
              />
            </a>
          </div>
        </div>

        <div className="footer-column">
          <div
            style={{
              width: 100,
              borderRadius: 40,
              paddingLeft: 5,
              paddingTop: 5,
              display: "flex",
              background: "#117A65",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <div style={{ paddingLeft: 10, lineHeight: 0.5 }}>
              <p style={{ fontWeight: "bold" }}>Download</p>
            </div>
          </div>
          <ul>
            <li>Application Forms(in soon)</li>
          </ul>
        </div>
        <div className="footer-column">
          <div
            style={{
              width: 100,
              borderRadius: 40,
              paddingLeft: 5,
              paddingTop: 5,
              display: "flex",
              background: "#117A65",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <div>
              {/* <img
                style={{ height: 40, width: 40, borderRadius: "50%" }}
                src="https://z-p3-scontent.fpnh18-1.fna.fbcdn.net/v/t39.30808-6/439312150_3740794849574851_1813006907713228606_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=5f2048&_nc_eui2=AeHQ1aot0r0EyqFBFxEbCR_ZuImi-ofXQJK4iaL6h9dAkorp5pW_4ggn24K1Gpkv1E-YcK_zeoKQPyIONVhAQ0h7&_nc_ohc=ItZmhXePtDcQ7kNvgGm9trh&_nc_zt=23&_nc_ht=z-p3-scontent.fpnh18-1.fna&oh=00_AYAJnHRWzhrrETMg7RtUf1ACJiLA88pzHb_eOwEazJbdZw&oe=6667A13E"
                alt="Raksmey Chann"
                className="footer-logo"
              /> */}
            </div>
            <div style={{ paddingLeft: 10, lineHeight: 0.5 }}>
              <p style={{ fontWeight: "bold" }}>Connecting</p>
            </div>
          </div>
          <ul>
            <li>United States,Cambodia</li>
          </ul>
        </div>
      </Footer>
    </Layout>
  );
};

export default LayoutPage;
