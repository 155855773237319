import React, { useState, useEffect } from "react";
import PostCard from "./PostCard";
import { useOutletContext } from "react-router-dom";
import "./PostList.css";
import { request } from "../share/request";
import { Circles } from "react-loader-spinner";
import { Button, Divider, Input, Pagination } from "antd";
import { useTranslation } from "react-i18next";
import { SiReadme } from "react-icons/si";
import { useNavigate } from "react-router-dom";
const PostList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { searchTerm } = useOutletContext();
  const [posts, setPosts] = useState([]);

  const [filteredNews, setFilteredNews] = useState([]);
  const [list_health, setList_health] = useState([]);
  const [list_travel, setList_travel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(null);
  const [txtSearchTerm, setSearchTerm] = useState("");
  const [objFilter, setObjFilter] = useState({
    page: 1,
    txtsearch: " ",
  });
  useEffect(() => {
    getListNews(objFilter);
  }, []);

  const getListNews = async (objFilter) => {
    const params = new URLSearchParams({
      page: objFilter.page,
      txtsearch: objFilter.txtsearch,
    });

    console.log("params", params);
    setLoading(true); // Set loading state before the request
    const res = await request("posts?" + params.toString(), "get", {});
    await new Promise((resolve) => setTimeout(resolve, 500));
    if (res) {
      console.log("response", res.posts_API);

      setFilteredNews(res.posts_API);
      setList_health(res.posts_API_health);
      setList_travel(res.posts_API_travel);
      setTotalPage(res.pagecount);

      if (objFilter.page === 1) {
        setTotalPage(res.pagecount[0].total);
      }
      setLoading(false); // Set loading state after the request
    } else {
      setLoading(false); // Handle the case when response is null
    }
  };
  useEffect(() => {
    if (searchTerm.length > 0) {
      var objTmp = {
        ...objFilter,
        txtsearch: searchTerm,
        page: searchTerm === "" ? 1 : objFilter.page,
      };
      setObjFilter(objTmp);
      getListNews(objTmp);
    } else {
      var objTmp = {
        ...objFilter,
        txtsearch: " ",
        page: 1,
      };

      setObjFilter(objTmp);
      getListNews(objTmp);
    }
  }, [searchTerm]);

  // const totalRecords = totalPage;
  // const pageSize = 10;
  // const pageCount = Math.ceil(totalRecords / pageSize);
  const onChangePageNumber = (page) => {
    var objTmp = {
      ...objFilter,
      page: page,
    };
    setObjFilter(objTmp);
    getListNews(objTmp);
  };
  const onMoreTech = () => {
    navigate("/technology");
  };
  const onMoreHealth = () => {
    navigate("/health");
  };
  const onMoreTravel = () => {
    navigate("/travel");
  };
  return (
    <div>
      <div className="sprinSave-container">
        {loading && (
          <div className="sprinSave">
            <Circles
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="circles-loading"
              visible={true}
            />
          </div>
        )}
      </div>
      {/* <Input type="text" onChange={(e) => onSearch(e.target.value)} /> */}
      <Divider orientation="left" plain>
        <div
          style={{
            backgroundColor: "#27AE60",
            borderRadius: "30px",
            paddingTop: "1px",
            paddingBottom: "1px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <p style={{ color: "white", fontSize: "16px", fontWeight: "bold" }}>
            {t("events")}
          </p>
        </div>
      </Divider>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        {/* <Pagination
          onChange={onChangePageNumber}
          className="custom-pagination"
          size="large"
          defaultCurrent={1}
          pageSize={5}
          total={totalPage}
        /> */}
      </div>
      <div className="post-list">
        {filteredNews.map((post, index) => (
          <div>
            <PostCard key={index} post={post} />
          </div>
        ))}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <div style={{ width: "70%" }}>
          <p> {""}</p>
        </div>
        <div
          onClick={onMoreTech}
          style={{
            alignItems: "center",
            paddingLeft: "10px",
            paddingRight: "10px",
            fontWeight: "bold",
            borderRadius: 50,
            color: "white",
            display: "flex",
            justifyContent: "center",
            background: "#6C3483",
            marginRight: 20,
            cursor: "pointer",
          }}
        >
          <SiReadme style={{ width: 25, height: 25 }} />{" "}
          <p style={{ paddingLeft: 10 }}> {t("readmore")}</p>
        </div>
      </div>

      <Divider orientation="left" plain>
        <div
          style={{
            backgroundColor: "#27AE60",
            borderRadius: "30px",
            paddingTop: "1px",
            paddingBottom: "1px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <p style={{ color: "white", fontSize: "16px", fontWeight: "bold" }}>
            {t("health")}
          </p>
        </div>
      </Divider>
      <div className="post-list">
        {list_health.map((post, index) => (
          <div>
            <PostCard key={index} post={post} />
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <div style={{ width: "70%" }}>
          <p> {""}</p>
        </div>
        <div
          onClick={onMoreHealth}
          style={{
            alignItems: "center",
            paddingLeft: "10px",
            paddingRight: "10px",
            fontWeight: "bold",
            borderRadius: 50,
            color: "white",
            display: "flex",
            justifyContent: "center",
            background: "#6C3483",
            marginRight: 20,
            cursor: "pointer",
          }}
        >
          <SiReadme style={{ width: 25, height: 25 }} />{" "}
          <p style={{ paddingLeft: 10 }}> {t("readmore")}</p>
        </div>
      </div>

      <Divider orientation="left" plain>
        <div
          style={{
            backgroundColor: "#27AE60",
            borderRadius: "30px",
            paddingTop: "1px",
            paddingBottom: "1px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <p style={{ color: "white", fontSize: "16px", fontWeight: "bold" }}>
            {t("travel")}
          </p>
        </div>
      </Divider>
      <div className="post-list">
        {list_travel.map((post, index) => (
          <div>
            <PostCard key={index} post={post} />
          </div>
        ))}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <div style={{ width: "70%" }}>
          <p> {""}</p>
        </div>
        <div
          onClick={onMoreTravel}
          style={{
            alignItems: "center",
            paddingLeft: "10px",
            paddingRight: "10px",
            fontWeight: "bold",
            borderRadius: 50,
            color: "white",
            display: "flex",
            justifyContent: "center",
            background: "#6C3483",
            marginRight: 20,
            cursor: "pointer",
          }}
        >
          <SiReadme style={{ width: 25, height: 25 }} />{" "}
          <p style={{ paddingLeft: 10 }}> {t("readmore")}</p>
        </div>
      </div>
    </div>
  );
};

export default PostList;
