import { BrowserRouter, Route, Routes } from "react-router-dom";
import LayoutPage from "./screen/components/Layout/Layout";
import Home from "./screen/home/Home";
import PostList from "./screen/post/PostList";
import DetailPage from "./screen/DetailPage/DetailPage";
import PostListTech from "./screen/technology/PostListTech";
import PostListHealth from "./screen/health/PostListHealth";
import PostListTravel from "./screen/travel/PostListTravel";
import About from "./screen/about/About";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutPage />}>
          <Route path="home" element={<PostList />} />
          <Route path="/" element={<PostList />} />
          <Route path="/post/:postId" element={<DetailPage />} />
          <Route path="share" element={<PostList />} />
          <Route path="technology" element={<PostListTech />} />
          <Route path="health" element={<PostListHealth />} />
          <Route path="travel" element={<PostListTravel />} />
          <Route path="about" element={<About />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
