import React from "react";

export default function About() {
  return (
    <div>
      <img
        style={{ height: "100%", width: "100%" }}
        src="https://reportapi.devcomputing.pro/photo/hmis_staff/aboutme"
      />
    </div>
  );
}
